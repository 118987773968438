import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { EnvService } from 'src/app/services/env.service';
import { FileService } from './file.service';
import { ServiceUtils } from '@/app/utils/service-utils';

import { httpCustomHeaderEnum } from '@/app/model/not-generated/enums/http-custom-header-enum';

@Injectable()
export class FileServiceInterceptor implements HttpInterceptor {
  /**
   * @param authService
   * @param _router
   * @param _envService
   */
  constructor(private fileService: FileService, private _router: Router, private _envService: EnvService) {}

  /**
   * req The request to . This is cloned before being passed to the next middleware.
   *
   * @param req - next The next middleware in the chain. This is used to intercept the uest.
   * @param next
   *
   * @return { HttpHandler } A middleware that intercepts the request and passes it on to the next middleware as soon as it has been intercepted
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    if (req.method == 'GET' && req.url.includes('/files/')) {
      req = ServiceUtils.addCustomHeader(req, httpCustomHeaderEnum.CUSTOM_REFERER.code, location.origin);
      return next.handle(req);
    }

    return next.handle(req);
  }
}
