import {
  AME,
  AMEPage,
  ContactInformationUpdateRequest,
  DemoteRequest,
  DoctorInformationUpdateRequest,
  PersonnalInformationUpdateRequest,
  ReportingUpdateRequest,
  SuspensionRequest,
} from 'src/app/model/ame';
import {
  AMEApprovalRequestPage,
  AmeApprovalRequestSearchCriteria,
  ApprovalRequestAmeCL2,
} from 'src/app/model/approvalRequestAmeCL2';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { AmeSearchCriteria } from 'src/app/model/ameSearchCriteria';
import { ApprovalRequestAmeCL1 } from 'src/app/model/approvalRequestAmeCL1';
import { EnvService } from 'src/app/services/env.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AMEService {
  /**
   * @param http
   * @param env
   */
  constructor(private http: HttpClient, private env: EnvService) {}

  /**
   * @param id - number The of the AME to retrieve.
   *
   * @return { Observable } The promise resolving to the AME with the specified id or undefined if not found. Note that it is possible to get multiple records at once
   */
  getById(id: number): Observable<AME> {
    return this.http.get<AME>(`${this.env.apiRootUrl}/ame/${id}`);
  }

  /**
   * @param id
   * @param updateRequest
   *
   * @return { Promise } Resolves to the updated bloc information for the doctor or rejects if there was an
   */
  updateDoctorBloc(id: number, updateRequest: DoctorInformationUpdateRequest): Observable<AME> {
    return this.http.put<AME>(`${this.env.apiRootUrl}/ame/${id}/doctor-bloc`, updateRequest);
  }

  /**
   * @param id
   * @param updateRequest
   *
   * @return { Promise } Resolves to the updated contact information or rejects with an error message. In case of success the object contains the contact's ID
   */
  updateContactBloc(id: number, updateRequest: ContactInformationUpdateRequest): Observable<AME> {
    return this.http.put<AME>(`${this.env.apiRootUrl}/ame/${id}/contact-bloc`, updateRequest);
  }

  /**
   * @param id
   * @param updateRequest
   *
   * @return { Observable } An observable that resolves to the response
   */
  updatePersonnalInformation(id: number, updateRequest: PersonnalInformationUpdateRequest): Observable<AME> {
    return this.http.put<AME>(`${this.env.apiRootUrl}/ame/${id}/contact-bloc-personnal`, updateRequest);
  }

  /**
   * @param id
   * @param updateRequest
   *
   * @return { Observable } A promise that resolves with the result of the update. The value of the Observable will be an object with the following properties
   */
  updateReportingBloc(id: number, updateRequest: ReportingUpdateRequest): Observable<AME> {
    return this.http.put<AME>(`${this.env.apiRootUrl}/ame/${id}/report-bloc`, updateRequest);
  }

  /**
   * @param id
   * @param ame
   *
   * @return { Observable } The updated instance of the AME with the ID set to the id parameter. This will be resolved when the update is complete
   */
  updateById(id: number, ame: AME): Observable<AME> {
    return this.http.put<AME>(`${this.env.apiRootUrl}/ame/${id}`, ame);
  }

  /**
   * @param searchCriteria
   * @param resultasParams
   *
   * @return { Object } A page of results
   */
  searchPagination(searchCriteria: AmeSearchCriteria, resultasParams: any): Observable<AMEPage> {
    let httpParams = new HttpParams({ fromObject: resultasParams });
    return this.http.post<AMEPage>(`${this.env.apiRootUrl}/ame/search`, searchCriteria, { params: httpParams });
  }

  /**
   * @param searchCriteria
   *
   * @return { Promise } Resolves with the export blob or rejects with an error message if there was an error
   */
  exportAME(searchCriteria: AmeSearchCriteria): Observable<HttpResponse<Blob>> {
    return this.http.post(`${this.env.apiRootUrl}/export/ame`, searchCriteria, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  /**
   * @param input
   * @param onlyCL1
   *
   * @return { Observable } A promise that resolves with an array of contacts
   */
  searchAutoComplete(input: string, onlyCL1: boolean = false): Observable<AME[]> {
    let formData: FormData = new FormData();
    formData.append('input', input);
    if (onlyCL1) {
      return this.http.post<AME[]>(`${this.env.apiRootUrl}/ame/cl1/search/autocomplete`, formData);
    } else {
      return this.http.post<AME[]>(`${this.env.apiRootUrl}/ame/search/autocomplete`, formData);
    }
  }

  // Returns all approvals that belong to the given AME ID. This is a convenience method
  getApprovalRequestsByAme(ameId: number): Observable<(ApprovalRequestAmeCL1 | ApprovalRequestAmeCL2)[]> {
    return this.http.get<(ApprovalRequestAmeCL1 | ApprovalRequestAmeCL2)[]>(
      `${this.env.apiRootUrl}/ame/${ameId}/approval-requests`
    );
  }

  /**
   * @param ameId
   * @param suspensionRequest
   *
   * @return { Observable } A promise that resolves with an array of contacts
   */
  suspendAgreement(ameId: number, suspensionRequest: SuspensionRequest): Observable<AME> {
    return this.http.put<AME>(`${this.env.apiRootUrl}/ame/${ameId}/suspension/true`, suspensionRequest);
  }

  /**
   * @param ameId
   * @param suspensionRequest
   *
   * @return { Observable } A promise that resolves with an array of contacts
   */
  resumeAgreement(ameId: number, suspensionRequest: SuspensionRequest): Observable<AME> {
    return this.http.put<AME>(`${this.env.apiRootUrl}/ame/${ameId}/suspension/false`, suspensionRequest);
  }

  /**
   * @param ameId
   * @param suspensionRequest
   *
   * @return { Observable } A promise that resolves with an array of contacts
   */
  revokeAgreement(ameId: number, suspensionRequest: SuspensionRequest): Observable<AME> {
    return this.http.put<AME>(`${this.env.apiRootUrl}/ame/${ameId}/revocation`, suspensionRequest);
  }

  /**
   * @param ameId
   * @param suspensionRequest
   *
   * @return { Observable } A promise that resolves with an array of contacts
   */
  endActivity(ameId: number, suspensionRequest: SuspensionRequest): Observable<AME> {
    return this.http.put<AME>(`${this.env.apiRootUrl}/ame/${ameId}/end-activity`, suspensionRequest);
  }

  /**
   * @param ameId
   * @param demoteRequest
   *
   * @return { Observable } A promise that resolves with an array of contacts
   */
  demoteAgreement(ameId: number, demoteRequest: DemoteRequest): Observable<AME> {
    return this.http.put<AME>(`${this.env.apiRootUrl}/ame/${ameId}/demote`, demoteRequest);
  }

  /**
   * @param ameId
   * @param fileId
   *
   * @return { Observable } A promise that resolves with an array of contacts
   */
  downloadCertificate(ameId: number, fileId: string): Observable<Blob> {
    return this.http.get(`${this.env.apiRootUrl}/ame/${ameId}/certificate/${fileId}`, { responseType: 'blob' });
  }

  /**
   * @param searchCriteria
   * @param resultsParams
   *
   * @return { Observable } A promise that resolves with an array of contacts
   */
  searchApprovalRequest(
    searchCriteria: AmeApprovalRequestSearchCriteria,
    resultsParams: any
  ): Observable<AMEApprovalRequestPage> {
    let httpParams = new HttpParams({ fromObject: resultsParams });
    return this.http.post<AMEApprovalRequestPage>(
      `${this.env.apiRootUrl}/ame/approval-requests/search`,
      searchCriteria,
      { params: httpParams }
    );
  }

  /**
   * @param searchCriteria
   *
   * @return { Observable } A promise that resolves with an array of contacts
   */
  exportResults(searchCriteria: AmeApprovalRequestSearchCriteria): Observable<HttpResponse<Blob>> {
    return this.http.post(`${this.env.apiRootUrl}/export/ame-approval`, searchCriteria, {
      observe: 'response',
      responseType: 'blob',
    });
  }
}
