<p-table
  [value]="searchResults"
  [lazy]="true"
  responsiveLayout="scroll"
  paginatorPosition="top"
  dataKey="id"
  [paginator]="true"
  [rows]="rowsPerPage"
  [totalRecords]="totalRecords"
  [loading]="loading"
  [(first)]="first"
  (onLazyLoad)="lazySearch.emit($event)"
>
  <ng-template pTemplate="header">
    <tr>
      <th id="tableColonnes" *ngFor="let colonne of tableColonnes" pSortableColumn="{{colonne.field}}">
        <span>{{colonne.header | translate}}</span>
        <p-sortIcon field="{{colonne.field}}"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-result>
    <tr
      [ngClass]="'table-line'"
      tabindex="0"
      (click)="redirectToDetail.emit(result)"
      (keypress)="redirectToDetail.emit(result)"
    >
      <td>{{result?.lastname}}</td>
      <td>{{result?.firstname}}</td>
      <td>{{result?.type?.slice(-1)}}</td>
      <td>{{'references.approvalRequest.type.' + result?.type | translate}}</td>
      <td>{{result?.requestDate | date:'dd/MM/yyyy'}}</td>
      <td>{{'references.approvalRequest.state.' + result?.state | translate}} {{showDecision ? showDecision(result) : ''}}</td>
    </tr>
  </ng-template>
</p-table>
