// Checks if the object is nil or undefined in the service group. This is used to determine if we should allow a non - nil object to be created
export const checkIsNil = (obj: any): boolean => {
  return obj === null || obj === undefined;
};

export const checkAreNil = (list: any[]): boolean => {
  return list.filter((o) => checkIsNil(o)).length === list.length;
};

export const chekHasNotNil = (list: any[]): boolean => {
  return list.some((o) => !checkIsNil(o));
};
