import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'custom-table',
  templateUrl: './custom-table.component.html',
})
export class CustomTableComponent {
  tableColonnes = [
    {
      field: 'lastname',
      header: 'approval-request.search.lastname',
    },
    {
      field: 'firstname',
      header: 'approval-request.search.firstname',
    },
    {
      field: 'classe',
      header: 'ame.model.class',
    },
    {
      field: 'type',
      header: 'ame.candidates.search.results.type',
    },
    {
      field: 'requestDate',
      header: 'ame.candidates.search.results.date',
    },
    {
      field: 'state',
      header: 'ame.candidates.search.results.state',
    },
  ];

  @Input() searchResults!: any[];
  @Input() rowsPerPage!: number;
  @Input() totalRecords!: number;
  @Input() loading!: boolean;
  @Input() first!: number;
  @Input() showDecision?: (result: any) => string;

  @Output() lazySearch = new EventEmitter<any>();
  @Output() redirectToDetail = new EventEmitter<any>();
}
