import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ACCESS_TOKEN_KEY, AUTHORITIES_KEY } from 'src/app/model/authorization';
import { EnvService } from 'src/app/services/env.service';
import { AuthService } from './auth.service';
import { TokenStorageService } from './token-storage.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**
   * private The service that manages token and environment data. Must be initialized before use. This is the first service to use in the .
   *
   * @param tokenService
   * @param authService
   * @param _router
   * @param _envService
   */
  constructor(
    private tokenService: TokenStorageService,
    private authService: AuthService,
    private _router: Router,
    private _envService: EnvService
  ) {}

  /**
   * req The request to . If there is an access token in localStorage it will be added to the request.
   *
   * @param req
   * @param next
   *
   * @return { Object } The response to pass to the next middleware or an error if it failed to intercept the request
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {

    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (token) {
      req = TokenInterceptor.addAuthorizationHeader(req, token)
    } else if(localStorage.getItem(AUTHORITIES_KEY)) {
      return next.handle(req);
    }

    if (req.url.includes('auth/login') || req.url.includes('auth/refresh')) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.refreshToken(req, next);
        }

        if (error instanceof HttpErrorResponse && error.status === 403) {
          this._router.navigate(['/not-allowed']);
        }

        return throwError(error);
      }));
  }

  /**
   * Refreshes the access token. This is done by checking if there is a refresh token in the cache and if so refreshes it.
   *
   * @param request - The request to process. Used for authorization headers.
   * @param next
   *
   * @return { Stream } A stream that when complete returns the new access token as a result of the refresh. If there is no refresh token the stream will be completed with an error
   */
  private refreshToken(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.tokenService.getRefreshToken();
      if (token) {
        return this.authService.refreshToken(token).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            this.tokenService.saveAccessToken(token.accessToken);
            this.tokenService.saveRefreshToken(token.refreshToken);
            this.refreshTokenSubject.next(token.accessToken);
            return next.handle(TokenInterceptor.addAuthorizationHeader(request, token.accessToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.tokenService.clear();
            this._router.navigate(['/login']);
            return throwError(err);
          })
        );
      }
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(TokenInterceptor.addAuthorizationHeader(request, token)))
    );
  }

  /**
   * Adds Authorization header to request. This is used to verify token in verification service. If token is updated in body we need to update token in body and new request will be returned
   *
   * @param request - Request to add header to
   * @param token
   *
   * @return New request with Authorization header added to it and new token in body if it's not valid for
   */
  private static addAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    //In case of error for WS verify we need to update token in body or we will have another failure
    this.updateTokenInVerificationService(request, token);
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }

  /**
   * Updates the token in the request if it exists. This is used to prevent cross - site request forgery attacks.
   *
   * @param request - The request to update. Must be a POST request.
   * @param token
   *
   * @return The request with the token updated if it exists or the original request otherwise ( in order to be compatible with IE8's HttpError handling conventions )
   */
  private static updateTokenInVerificationService(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (request.body && request.body.token) {
      request.body.token = token;
    }
    return request;
  }
}
