<sb-sub-header title="{{ getApprovalRequestTitle() | translate}}">
  <div class="header-actions">
    <sb-header-action>
      <button pButton (click)="goHome()" >{{'widget.dashboard.buttons.goHome' | translate }}</button>
    </sb-header-action>
  </div>
</sb-sub-header>

<div class="approval-requests-content">
  <div class="approval-requests-count">
    <strong>{{ searchResults?.length }} {{'widget.dashboard.request' | translate }}</strong>
  </div>

  <div >
    <custom-table
      *ngIf="searchResults"
      [searchResults]="searchResults"
      [rowsPerPage]="rowsPerPage"
      [totalRecords]="totalRecords"
      [loading]="loading"
      [first]="first"
      (lazySearch)="lazySearch($event)"
      (redirectToDetail)="redirectToDetail($event)"
    ></custom-table>
  </div>
</div>
