import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { EnvService } from 'src/app/services/env.service';
import { Observable } from 'rxjs';
import { HistoricFile } from 'src/app/model/historicFile';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  /**
   * @param http
   * @param env
   */
  constructor(private http: HttpClient, private env: EnvService) {}

  /**
   * @param fileId
   *
   * @return { Promise } Resolves to the file's data or rejects with an error message if there was an error
   */
  getFile(fileId: string): Observable<Blob> {
    return this.http.get(`${this.env.apiRootUrl}/files/${fileId}`, { responseType: 'blob' });
  }

  /**
   * @param fileId
   *
   * @return { Observable } Resolves to an array of HistoricFile instances or rejects with an error message
   */
  getFileHistoric(fileId: string): Observable<HistoricFile[]> {
    return this.http.get<HistoricFile[]>(`${this.env.apiRootUrl}/files/historic/${fileId}`);
  }

  /**
   * @param fileId
   *
   * @return { Observable } A promise that resolves with an HttpResponse once the file has been deleted and all metadata associated with it
   */
  deleteFileHistoric(fileId: string): Observable<HttpResponse<HttpStatusCode>> {
    return this.http.delete<HttpResponse<HttpStatusCode>>(`${this.env.apiRootUrl}/files/historic/${fileId}`);
  }

  /**
   * @param filename
   *
   * @return { Promise } Resolves to the blob that contains the file's contents or rejects if there is an error
   */
  getCommonFileByName(filename: string): Observable<Blob> {
    return this.http.get(`${this.env.apiRootUrl}/files/common/${filename}`, { responseType: 'blob' });
  }
}
