/* The AppComponent class is an Angular component that handles internationalization (i18n) for the
PrimeNG library. */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(private _translateService: TranslateService, private _primeNGConfig: PrimeNGConfig) {}

  primeNGI18nSubscription!: Subscription;

  ngOnInit() {
    this.primeNGI18nSubscription = this._translateService.stream('primeng').subscribe((data) => {
      this._primeNGConfig.setTranslation(data);
    });
  }

  ngOnDestroy() {
    this.primeNGI18nSubscription.unsubscribe();
  }
}
