<div class="sub-header_title">
  <h1>{{ title }}</h1>
  <h2 class="large-text" *ngIf="subTitle">{{ subTitle }}</h2>
</div>
<div class="indicator-group">
  <div class="indicator" *ngIf="infoToDisplay">
    <fa-icon class="slight-padding-right" *ngIf="icon" [icon]="icon"></fa-icon>{{ infoToDisplay.label }}
  </div>
  <div class="indicator" *ngIf="infoToDisplaySurveillance">{{ infoToDisplaySurveillance }}</div>
</div>

<div class="sub-header_content">
  <ng-content></ng-content>
</div>
