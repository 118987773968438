import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TranslateResolver implements Resolve<any> {
  /**
   * @param _translateService - Reference to the service that handles translating messages
   */
  constructor(private _translateService: TranslateService) {}

  /**
   * @param route - state The state of the r. This is the most recent ` RouterState ` in the system.
   * @param state
   *
   * @return { string } The translated string to use for this route or undefined if there is no translation for this route
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this._translateService.use('fr');
  }
}
