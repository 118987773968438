import { ErrorHandler, NgModule, Predicate } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EnvServiceProvider } from './services/env.service.provider';
import { TokenInterceptor } from 'src/app/services/authentication/token.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  AbstractControl,
  DefaultValueAccessor,
  FormArray,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
} from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { AuthInterceptor } from './services/authentication/auth.interceptor';
import { AppErrorsHandler } from './utils/error-handler/error-client-handler';
import { AriaLabeler } from './utils/aria-utils';
import { FileServiceInterceptor } from './services/files/file.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'fr',
      useDefaultLang: true,
    }),
  ],
  providers: [
    EnvServiceProvider,
    MessageService,
    AriaLabeler,
    [
      { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: FileServiceInterceptor, multi: true },
      { provide: ErrorHandler, useClass: AppErrorsHandler },
    ],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  /*
    In forms if we start to write value inside of input and clear it after with backspace the value is no longer null but empty string
    Too avoid @ValidationError because of pattern we need to set this string to null again using this hack
  */
  constructor() {
    DefaultValueAccessor.prototype.registerOnChange = function (fn: (_: string | null) => void): void {
      this.onChange = (value: string | null) => {
        fn(value === '' ? null : value);
      };
    };
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function conditionalValidator(predicate: Predicate<FormGroup | FormArray>, validator: ValidatorFn) {
  return (formControl: AbstractControl) => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate(formControl.parent)) {
      return validator(formControl);
    }
    return null;
  };
}
