import {
  ACCESS_TOKEN_KEY,
  AEMC_ID_KEY,
  AME_CLASS_KEY,
  AME_ID_KEY,
  ASSOCIATED_ID_KEY,
  AUTHORITIES_KEY,
  CENTER_CHIEF_KEY,
  DOCTOR_CHIEF_KEY,
  ERROR,
  IS_CONNECTED,
  PROFILE_CODE_KEY,
  PROFILE_TYPE_KEY,
  REFRESH_TOKEN_KEY,
  REQUEST_ID_KEY,
  USER_NAME_KEY,
} from 'src/app/model/authorization';

import { Injectable } from '@angular/core';
import { checkIsNil } from '@/app/utils/app-utils';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  /**
   * / / object / list to be used in a call to any of the methods of the object
   */
  clear(): void {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(USER_NAME_KEY);
    localStorage.removeItem(AUTHORITIES_KEY);
    localStorage.removeItem(PROFILE_TYPE_KEY);
    localStorage.removeItem(ASSOCIATED_ID_KEY);
    localStorage.removeItem(AEMC_ID_KEY);
    localStorage.removeItem(AME_ID_KEY);
    localStorage.removeItem(AME_CLASS_KEY);
    localStorage.removeItem(CENTER_CHIEF_KEY);
    localStorage.removeItem(DOCTOR_CHIEF_KEY);
    localStorage.removeItem(REQUEST_ID_KEY);
    localStorage.removeItem(PROFILE_CODE_KEY);
    localStorage.removeItem(IS_CONNECTED);
  }

  /**
   * @param token - The access token to save to local storage for future use
   */
  public saveAccessToken(token: string): void {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
  }

  /**
   * @return { string|null } access token or null if not found in local storage or expired ( no token
   */
  public getAccessToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  /**
   * @param token - The new refresh token to save to local storage
   */
  public saveRefreshToken(token: string): void {
    localStorage.setItem(REFRESH_TOKEN_KEY, token);
  }

  /**
   * @return { string|null } Refresh token or null if not found in local storage or expired ( in which case it is expired
   */
  public getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  /**
   * @param username
   */
  public saveUsername(username: string): void {
    localStorage.setItem(USER_NAME_KEY, username);
  }

  /**
   * @return { string|null } user name or null if not found in local storage ( no error will be thrown
   */
  public getUsername(): string | null {
    return localStorage.getItem(USER_NAME_KEY);
  }

  /**
   * @param authorities - The authorities to save to local storage
   */
  public saveAuthorities(authorities: string[]): void {
    localStorage.setItem(AUTHORITIES_KEY, JSON.stringify(authorities));
  }

  /**
   * Get authorities from local storage. If there are no authorities return null. This is used to determine if we are running on mobile or not.
   *
   *
   * @return { string [] |null } authorities or null if none found in local storage or not parsed from
   */
  public static getAuthorities(): string[] | null {
    if (localStorage.getItem(AUTHORITIES_KEY)) {
      return JSON.parse(localStorage.getItem(AUTHORITIES_KEY)!);
    } else {
      return null;
    }
  }

  /**
   * @param profileType - The profile type to save to localStorage. This is a string
   */
  public saveProfilType(profileType: string): void {
    localStorage.setItem(PROFILE_TYPE_KEY, profileType);
  }

  /**
   * Returns the profile type stored in local storage. If it doesn't exist returns null. This is used to determine if we are going to show a profile or not.
   *
   *
   * @return The profile type stored in local storage or null if not found or not set ( in which case it's a profile
   */
  public static getProfilType(): string | null {
    return localStorage.getItem(PROFILE_TYPE_KEY);
  }

  /**
   * @param associatedId
   */
  public saveAssociatedId(associatedId: number): void {
    localStorage.setItem(ASSOCIATED_ID_KEY, JSON.stringify(associatedId));
  }

  /**
   * @return { string|null } associated id or null if not found or not a valid assocaited id
   */
  public getAssociatedId(): number | null {
    return TokenStorageService.getStorageNumber(ASSOCIATED_ID_KEY);
  }

  /**
   * @param ameId
   */
  public saveAmeId(ameId: number): void {
    localStorage.setItem(AME_ID_KEY, JSON.stringify(ameId));
  }

  /**
   * @param ameClass
   */
  public saveAmeClass(ameClass: string): void {
    localStorage.setItem(AME_CLASS_KEY, JSON.stringify(ameClass));
  }

  /**
   * @param aemcId
   */
  public saveAemcId(aemcId: number): void {
    localStorage.setItem(AEMC_ID_KEY, JSON.stringify(aemcId));
  }

  /**
   * Gets the AME ID from local storage. This is used to determine which user is making the request to the API.
   *
   *
   * @return { string|null } The AME ID or null if none is stored in local storage or there was an error
   */
  public static getAmeId(): number | null {
    return this.getStorageNumber(AME_ID_KEY);
  }

  /**
   * Get the AME class from localStorage. This is used to determine whether or not we are in development mode.
   *
   *
   * @return { string|null } The AME class or null if not in development mode or there is no AME
   */
  public static getAmeClass(): string | null {
    return localStorage.getItem(AME_CLASS_KEY) ? JSON.parse(localStorage.getItem(AME_CLASS_KEY)!) : null;
  }

  /**
   * Returns AEMC ID from local storage. If it doesn't exist null is returned. This is used to determine which user to log in when a user clicks on the link in the web UI.
   *
   *
   * @return { string|null } The AEMC ID or null if it doesn't exist in local storage
   */
  public static getAemcId(): number | null {
    return this.getStorageNumber(AEMC_ID_KEY);
  }

  /**
   * @param isChefCentre
   */
  public saveChefCentre(isChefCentre: boolean): void {
    localStorage.setItem(CENTER_CHIEF_KEY, JSON.stringify(isChefCentre));
  }

  /**
   * Returns the chief centre from local storage. If it doesn't exist false is returned.
   *
   *
   * @return { boolean } true if chief centre exists false otherwise. False will be returned if it doesn't exist
   */
  public static getChefCentre(): boolean {
    return localStorage.getItem(CENTER_CHIEF_KEY) ? JSON.parse(localStorage.getItem(CENTER_CHIEF_KEY)!) : false;
  }

  /**
   * @param isMedecinChef
   */
  public saveMedecinChef(isMedecinChef: boolean): void {
    localStorage.setItem(DOCTOR_CHIEF_KEY, JSON.stringify(isMedecinChef));
  }

  /**
   * Returns the value of the chef indicator. It is true if the user has decided to cherry pick the chef false otherwise.
   *
   *
   * @return { boolean } True if he feture is chef false otherwise ( default : false ). In case of failure returns false
   */
  public static getMedecinChef(): boolean {
    return localStorage.getItem(DOCTOR_CHIEF_KEY) ? JSON.parse(localStorage.getItem(DOCTOR_CHIEF_KEY)!) : false;
  }

  /**
   * @param requestId
   */
  public saveRequestId(requestId: number): void {
    localStorage.setItem(REQUEST_ID_KEY, JSON.stringify(requestId));
  }

  /**
   * Gets the request id from local storage. If it doesn't exist null is returned. This is used to prevent an attacker from trying to re - establish a connection to the server when it's in the process of shutting down.
   *
   *
   * @return { string|null } request id or null if not found in local storage or invalid JSON object if
   */
  public static getRequestId(): number | null {
    return this.getStorageNumber(REQUEST_ID_KEY);
  }

  /**
   * @param profileCode - Profile code to be saved to local storage
   */
  public saveProfileCode(profileCode: string) {
    localStorage.setItem(PROFILE_CODE_KEY, JSON.stringify(profileCode));
  }

  /**
   * Get profile code from local storage. If it doesn't exist return null. This is used to determine if we are running in production mode and can be removed in future versions.
   *
   *
   * @return { string|null } Profile code or null if not found in local storage or invalid JSON object ( which could be due to browser bug
   */
  public static getProfileCode(): string | null {
    return localStorage.getItem(PROFILE_CODE_KEY) ? JSON.parse(localStorage.getItem(PROFILE_CODE_KEY)!) : null;
  }

  /**
   * @param connected - Whether or not we are connected to the server
   */
  public setIsConnected(connected: boolean) {
    localStorage.setItem(IS_CONNECTED, JSON.stringify(connected));
  }

  /**
   * @return { boolean } true if connected false if not connected or not in local storage. This is useful for checking if we are connected
   */
  public getIsConnected(): boolean {
    if (!localStorage.getItem(IS_CONNECTED)) {
      return false;
    }
    return JSON.parse(localStorage.getItem(IS_CONNECTED)!);
  }

  /**
   * @return { boolean } Whether or not the user is authenticated with CPS ( default : false ). This is the case if we have a refresh token
   */
  public isAutenticatedWithCps(): boolean {
    return this.getRefreshToken() === null;
  }

  /**
   * Checks if connected user with CPS card has refreshToken error
   * @returns boolean value
   */
  public hasCpsConnectionError(): boolean {
    return !checkIsNil(localStorage.getItem(ERROR));
  }

  private static getStorageNumber(key: string): number | null {
    const storageValue = localStorage.getItem(key);
    if (
      storageValue === undefined ||
      storageValue === null ||
      storageValue === 'undefined' ||
      storageValue === 'null'
    ) {
      return null;
    }
    return JSON.parse(storageValue);
  }
}
