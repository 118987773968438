import { CandidateBasicInfo, DoctorBasicInfo } from './user';

import { AME } from './ame';
import { Address } from './address';
import { ApprovalRequestAmeCL1, ApprovalRequestType } from './approvalRequestAmeCL1';
import { ApprovalRequestFiles } from './ApprovalRequestFiles';
import { ApprovalType } from './approvalType';
import { Audit } from './audit';
import { FileInfo } from './file';
import { FormGroup } from '@angular/forms';
import { CivilityDto } from '@/appgenerated/model/personnel-navigant/civility-dto';
import { GenderDto } from '@/appgenerated/model/common/gender-dto';

export class ApprovalRequestAmeCL2 {
  id!: number;
  type!: ApprovalType;
  state!: string;
  requestDate!: Date;
  doctorInCharge!: DoctorBasicInfo;
  firstname!: string;
  lastname!: string;
  birthDate!: Date;
  gender!: GenderDto;
  civility!: CivilityDto;
  personalAddress!: Address;
  personalPhoneNumber!: string;
  personalMobilePhoneNumber!: string;
  personalEmail!: string;
  generalist!: boolean;
  speciality!: string;
  exerciseConditions!: ExerciseCondition[];
  comment!: string;
  professionalAddress!: Address;
  professionalPhoneNumber!: string;
  professionalMobilePhoneNumber!: string;
  professionalEmail!: string;
  diffusionFixe!: boolean;
  diffusionMobile!: boolean;
  fax!: string;
  rppsNumber!: number;
  website!: string;
  ecg!: boolean;
  audiogram!: boolean;
  otherInstrument!: string;
  curriculum!: string;
  approvalRequestFiles!: ApprovalRequestFiles[];
  candidate!: CandidateBasicInfo;
  candidateInfo!: CandidateInfo;
  audit!: Audit;
  isApproved!: boolean;
  decisionDate!: Date;
  validityEndDate!: Date;
  decisionComment!: string;
  decisionFile!: FileInfo;
  ame!: AME;
  onHoldDate!: Date;
  onHoldComment!: string;

  examNumber!: number;
  formationHours!: number;

  approvalRequestType?: ApprovalRequestType;
  isSigned!: boolean;

  public constructor(init?: Partial<ApprovalRequestAmeCL2>) {
    Object.assign(this, init);
  }
}

export class ExerciseCondition {
  code!: string;
  label!: string;
}

export class ApprovalRequestCL2PersonalInfo {
  firstname!: string;
  lastname!: string;
  birthDate!: Date;
  gender!: GenderDto;
  personalAddress!: Address;
  personalPhoneNumber!: string;
  personalMobilePhoneNumber!: string;
  personalEmail!: string;
  rppsNumber!: number;
  website!: string;
}

export class ApprovalRequestCL2ProfessionalInfo {
  id!: number;
  professionalAddress!: Address;
  professionalPhoneNumber!: string;
  professionalMobilePhoneNumber!: string;
  professionalEmail!: string;
  diffusionFixe!: boolean;
  diffusionMobile!: boolean;
  fax!: string;
}

export class ApprovalRequestCL2ProrogationPersonalInfo {
  website!: string;
  professionalAddress!: Address;
  professionalPhoneNumber!: string;
  diffusionFixe!: boolean;
  professionalMobilePhoneNumber!: string;
  diffusionMobile!: boolean;
  professionalEmail!: string;
  fax!: string;
  generalist!: boolean;
  speciality!: string;
  exerciseConditions!: ExerciseCondition[];
  comment!: string;
  examNumber!: number;
  formationHours!: number;
}

export class ApprovalRequestCL2RequestInfo {
  id!: number;
  generalist!: boolean;
  speciality!: string;
  curriculum!: string;
  exerciseConditions!: ExerciseCondition[];
  ecg!: boolean;
  audiogram!: boolean;
  otherInstrument!: string;
  type!: string;
  state!: string;
}

export class FileDeletionRequest {
  fileId!: string;
  fileCategory!: string;
}

export class CandidateInfo {
  id!: number;
  mainAddress!: Address;
  personalPhoneNumber!: string;
  personalMobilePhoneNumber!: string;
  mainMail!: string;
  secondaryMail!: string;
  secondaryAddress!: Address | null;
  comment!: string;
}

export const STATE_A_TRAITER = 'A_TRAITER';
export const STATE_EN_COURS = 'EN_COURS';
export const STATE_TRAITEE = 'TRAITEE';
export const STATE_EN_ATTENTE = 'EN_ATTENTE';

export class AmeApprovalRequestSearchCriteria {
  lastname!: string;
  firstname!: string;
  type?: string;
  state?: string;

  public constructor(init?: Partial<AmeApprovalRequestSearchCriteria>) {
    Object.assign(this, init);
  }
}

export function createSearchCriteria(searchCriteriaForm: FormGroup): AmeApprovalRequestSearchCriteria {
  return new AmeApprovalRequestSearchCriteria(searchCriteriaForm.value);
}

export interface AMEApprovalRequestPage {
  content: (ApprovalRequestAmeCL1 | ApprovalRequestAmeCL2)[];
  pageable: {
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    offset: number;
    pageSize: number;
    pageNumber: number;
    page: boolean;
    unpaged: boolean;
  };
  last: boolean;
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}
