/* The AppRoutingModule class defines the routing configuration for the application, including the
routes for different components and modules. */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateResolver } from './resolver/translate.resolver';
import { AuthResolver } from './resolver/auth.resolver';
import { AuthGuard } from './guards/auth.guard';
import { ApprovalRequestsListingComponent } from './dashboard/widget-approval-request/approval-request-detail/approval-requests-listing/approval-requests-listing.component';

const routes: Routes = [
  {
    path: 'app',
    canActivateChild: [AuthGuard],
    resolve: {
      lang: TranslateResolver,
      auth: AuthResolver,
    },
    loadChildren: () => import('./frame/frame.module').then((m) => m.FrameModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  {
    path: 'not-allowed',
    loadChildren: () => import('./not-allowed/not-allowed.module').then((m) => m.NotAllowedModule),
  },
  {
    path: '',
    redirectTo: '/app',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/error',
    pathMatch: 'full',
  },
  { path: 'approval-requests-listing', component: ApprovalRequestsListingComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
