import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenStorageService } from 'src/app/services/authentication/token-storage.service';
import { AuthService } from '../services/authentication/auth.service';

import { EnvService } from '../services/env.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _tokenStorageService: TokenStorageService,
    private _envService: EnvService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._authService.isConnected()) {
      return true;
    }

    const accessToken = this._tokenStorageService.getAccessToken();
    const username = this._tokenStorageService.getUsername();
    /**
     *
     * Dans le cas d'une authentification via le LDAP un AccessToken sera généré, dans le cas d'une authentification par la carte CPS l'utilisateur
     * sera automatiquement renvoyé vers la page d'accueil de l'application en cas d'authentification réussie.
     * Dans le cas d'une authentification par carte CPS on renvoie true dans ce guard, le resolver d'authentification appelé par la suite
     * permettra de confirmer l'authentication de la carte CPS et renverra vers la page de login dans le cas échéant
     *
     */
    if (accessToken && username) {
      return this._authService.verifyToken(accessToken, username).pipe(
        map((verified) => {
          if (verified) {
            this._authService.setConnected(true);
            return true;
          } else {
            return false;
          }
        })
      );
    } else {
      return true;
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
