import { ReferenceType } from './shared-enum';

export const httpCustomHeaderEnum: httpCustomHeaderType = {
  CUSTOM_REFERER: {
    code: 'archireferer',
    label: 'references.http.customHeader.customReferer',
  },
  getLabel: function (code: string) {
    const headers = Object.values(httpCustomHeaderEnum);
    const header = headers.find((e) => typeof e !== 'function' && e.code === code);
    if (typeof header !== 'function') {
      return header!.label;
    }
    return 'undefined';
  },
};

type httpCustomHeaderType = {
  CUSTOM_REFERER: ReferenceType;
  getLabel: (value: string) => string;
};
