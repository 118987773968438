export class AuthorizationResponse {
  accessToken!: string;
  refreshToken!: string;
  username!: string;
  authorities!: string[];
  profileTypeCode!: string;
  id!: number;
  centerManager!: boolean;
  doctorManager!: boolean;
  requestId!: number;
  ameId!: number;
  ameClass!: string;
  aemcId!: number;
  profileCode!: string;
}

export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const USER_NAME_KEY = 'username';
export const AUTHORITIES_KEY = 'authorities';
export const PROFILE_TYPE_KEY = 'profile_type_code';
export const PROFILE_CODE_KEY = 'profile_code';
export const ASSOCIATED_ID_KEY = 'associated_id';
export const CENTER_CHIEF_KEY = 'is_center_manager';
export const DOCTOR_CHIEF_KEY = 'is_doctor_manager';
export const REQUEST_ID_KEY = 'request_id';
export const AME_ID_KEY = 'ameId';
export const AME_CLASS_KEY = 'ameClass';
export const AEMC_ID_KEY = 'aemcId';
export const IS_CONNECTED = 'is_connected';
// SET FROM PSC WITH CARD CONNECTION
export const ERROR = 'error';
