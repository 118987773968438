import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/shared/toast/toast.service';
import { HttpErrorResponse } from '@angular/common/http';

/*
  Service pour intercepter toutes les erreurs Clients survenants dans l'application
*/
@Injectable()
export class AppErrorsHandler implements ErrorHandler {
  /**
   * @param zone
   * @param _toastService
   */
  constructor(private zone: NgZone, private _toastService: ToastService) {}

  /**
   * @param error
   */
  handleError(error: Error): void {
    if(error instanceof HttpErrorResponse) {
      localStorage.error = error.error.message;
      this.zone.run(() => {
        this._toastService.showErrorFromHttp(error);
      })
    } else {
      localStorage.error = error.message;
      if (environment.production) {
        this.displayError('global.toast.error.defautMessage')
      } else {
        console.error(error);
        this.displayError(error.message);
      }
    }
  }

  /*
  TODO: Réussir à corriger le problème avec les erreurs se passant sur les pages HTML
  Le toaster n'apparaît pas avec les messages, l'utilisation de la methode alert fonctionne correctement mais le message apparaît en boucle
  */
  /**
   * @param message
   */
  private displayError(message: string) {
    this.zone.run(() => {
      this._toastService.showError({ description: message });
    });
  }
}
