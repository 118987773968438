import { EnvService } from "./env.service";

export const EnvServiceFactory = () => {
  // Create env
  const env = new EnvService();

  // Read environment variables from browser window
  const browserWindow : any  = window || {};
  const browserWindowEnv : any = browserWindow['__env'] || {};

  // Assign environment variables from browser window to env
  for (const key in browserWindowEnv) {
    if (browserWindowEnv.hasOwnProperty(key)) {
      (env as { [key: string]: any })[key] = (window as { [key: string]: any })["__env"][key];
    }
  }
  return env;
};

export const EnvServiceProvider = {
  provide: EnvService,
  useFactory: EnvServiceFactory,
  deps: [],
};
