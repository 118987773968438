import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { Subscription } from 'rxjs';

import { AMEService } from '@/app/services/pole-medical/ame/ame.service';
import { ToastService } from '@/shared/toast/toast.service';
import { ApprovalRequestAmeCL2, createSearchCriteria } from 'src/app/model/approvalRequestAmeCL2';
import { ApprovalRequestAmeCL1 } from '@/app/model/approvalRequestAmeCL1';
import { WidgetApprovalRequestAmeDto } from '@/appgenerated/model/pole-medical/widget-approval-request-ame-dto';
import { AriaLabeler } from '@/app/utils/aria-utils';
import { ApprovalType } from '@/app/model/approvalType';

@Component({
  selector: 'approval-requests-listing',
  templateUrl: './approval-requests-listing.component.html',
  styleUrls: ['./approval-requests-listing.component.scss']
})
export class ApprovalRequestsListingComponent implements OnInit {
  
  widgetApprovalRequestAmeDtoList: WidgetApprovalRequestAmeDto[] = [];
  searchCriteriaForm!: FormGroup;
  pageNumber: number = 0;
  first: number = 0;
  loading: boolean = false;
  searchSubscription!: Subscription;
  sortCriteria!: string | null;
  sortOrder!: number;
  searchResults!: (ApprovalRequestAmeCL2 | ApprovalRequestAmeCL1)[];
  totalRecords!: number;
  stateApprovalRequest!: string;
  typeApprovalRequest!: string;

  readonly rowsPerPage = 50;

  constructor(
    private route: ActivatedRoute,
    private _ameService: AMEService,
    private _toastService: ToastService,
    private searchCriteriaFormBuilder: FormBuilder,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private ariaUtil: AriaLabeler,
    private _router: Router,

  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const state = params['state'];
      const type = params['type'];
      this.searchCriteriaForm = this.searchCriteriaFormBuilder.group({
        state: [state],
        type: [type]
      });
      this.stateApprovalRequest = state;
      this.typeApprovalRequest = type;
      this.searchApprovalRequest(true);
    });
  }
  
  public ngAfterViewChecked(): void {
    //Adds aria-label to the pagination elements in all tables.
    this.ariaUtil.addsAriaLabel2Paginators();
  }

  /**
  * Prepares pagination and sorting parameters for search.
  * @returns Object with page and sort details.
  */
  private createSearchPageParam() {
    let pageParams = { page: this.pageNumber };
    if (this.sortCriteria) {
      Object.assign(pageParams, { sortedBy: this.sortCriteria });
    }
    if (this.sortOrder) {
      Object.assign(pageParams, { order: this.sortOrder });
    }
    return pageParams;
  }

  /**
  * Handles table pagination and sorting.
  * Triggers search with updated parameters.
  * @param event Contains pagination and sorting details.
  */
  lazySearch(event: LazyLoadEvent) {
    this.loading = true;
    this.pageNumber = event?.first! / this.rowsPerPage;
    if (event?.sortOrder) {
      this.sortOrder = event?.sortOrder!;
    }
    if (event?.sortField) {
      this.sortCriteria = event?.sortField!;
    }
    this.searchApprovalRequest();
  }

  /**
* Executes search for approval requests with form criteria.
* Option to reset pagination.
* @param resetPageNumber Resets page number if true.
*/
searchApprovalRequest(resetPageNumber?: boolean) {
  if (resetPageNumber) {
    this.pageNumber = 0;
    this.first = 0;
  }
  this.loading = true;
  const searchParams = this.createSearchPageParam();
  const searchCriteria = createSearchCriteria(this.searchCriteriaForm);
  
  this.searchSubscription = this._ameService.searchApprovalRequest(searchCriteria, searchParams).subscribe(
    response => {
      this.searchResults = response.content;
      this.totalRecords = response.totalElements;
      this.loading = false;
      this.cdr.detectChanges();
    },
    error => {
      this.loading = false;
      this._toastService.showErrorFromHttp(error);
      this.cdr.detectChanges();
    }
  );
}
  /**
 * Redirects to the AME approval request detail page based on its type (CL1 or CL2).
 * @param request The AME approval request object.
 */
  redirectToDetail(request: ApprovalRequestAmeCL2 | ApprovalRequestAmeCL1) {
  if (!ApprovalType.isCL1(request.type)) {
    this._router.navigate([`app/ame/approval-request/cl2/${request.id}`]);
  } else {
    this._router.navigate([`app/ame/approval-request/cl1/${request.id}`]);
  }
}

   /**
  * Gets title for approval request's current state.
  * @returns state title.
  */
  getApprovalRequestTitle(): string {
    return this.stateApprovalRequest === 'A_TRAITER'
      ? "references.approvalRequest.listingApprovalRequest.toProcess."+this.typeApprovalRequest.toLowerCase()
      : "references.approvalRequest.listingApprovalRequest.pending."+this.typeApprovalRequest.toLowerCase();
  }

   /**
  * Navigates to the home page.
  */
  goHome() {
    this.router.navigate(['/app','home']); 
  }

}
