import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IconDefinition, faHand } from '@fortawesome/free-solid-svg-icons';

import { PnActionInProgress } from '@/appgenerated/model/personnel-navigant/references';

@Component({
  selector: 'sb-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'sub-header',
  },
})
export class SubHeaderComponent implements OnInit {
  @Input()
  title!: string;

  @Input()
  subTitle!: string;

  @Input()
  infoToDisplaySurveillance?: string;

  @Input()
  infoToDisplay?: PnActionInProgress;

  icon?: IconDefinition;

  /**
   * / / object / list object is used to determine the type of object that is being
   */
  ngOnInit() {
    if (this.infoToDisplay?.code === 'VISITE') {
      this.icon = faHand;
    }
  }
}
