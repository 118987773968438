import { AuthorizationResponse } from 'src/app/model/authorization';
import { EnvService } from 'src/app/services/env.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * @param http
   * @param env
   * @param _tokenStorageService
   */
  constructor(private http: HttpClient, private env: EnvService, private _tokenStorageService: TokenStorageService) {}

  /**
   * @param username
   * @param password
   *
   * @return { Object } The response from Brightcove that contains the user's credentials. This is required
   */
  signIn(username: string, password: string): Observable<AuthorizationResponse> {
    return this.http.post<AuthorizationResponse>(`${this.env.apiRootUrl}/auth/login`, { username, password });
  }

  /**
   * @param token
   * @param username
   */
  verifyToken(token: string | null, username: string | null) {
    return this.http.post(`${this.env.apiRootUrl}/auth/verify`, { token, username });
  }

  /**
   * @param refreshToken
   * @return True if refresh token is still valid. False otherwise.
   */
  verifyRefreshToken(refreshToken: string | null) {
    return this.http.post(`${this.env.apiRootUrl}/auth/verify-refresh`, { refreshToken });
  }


  /**
   * The refresh token to use. This is required for API keys that require a refresh token.
   *
   *
   * @return { Promise } Resolves to the response from the API call or rejects with an error message if there was an error
   */
  refreshToken(refreshToken: string) {
    return this.http.post(`${this.env.apiRootUrl}/auth/refresh`, { refreshToken });
  }

  /**
   * @return { Observable } Authentication info from api. It's an Observable so you can listen to it's
   */
  getAuthenticationInfo(): Observable<AuthorizationResponse> {
    return this.http.get<AuthorizationResponse>(`${this.env.apiRootUrl}/auth`);
  }

  /**
   * @param state
   */
  setConnected(state: boolean) {
    this._tokenStorageService.setIsConnected(state);
  }

  /**
   * @return { boolean } True if connected false otherwise. This is a helper method to avoid calling it multiple times
   */
  isConnected(): boolean {
    return this._tokenStorageService.getIsConnected();
  }

  /**
   * @return { Observable } The observable that resolves when the logout is complete or rejects with an error message if there is an error
   */
  logOut(): Observable<Logout> {
    return this.http.post<Logout>(`${this.env.apiRootUrl}/auth/logout`, {});
  }
}

export class Logout {
  logoutUrl!: string;
  idToken!: string;
  postLogOutUri!: string;

  constructor(init?: Partial<Logout>) {
    Object.assign(this, init);
  }
}
