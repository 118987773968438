import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from 'src/app/services/authentication/auth.service';
import { AuthorizationResponse } from '../model/authorization';
import { EnvService } from '../services/env.service';
import { Injectable } from '@angular/core';
import { TokenStorageService } from 'src/app/services/authentication/token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthResolver implements Resolve<any> {
  /**
   * @param _authService
   * @param _tokenStorageService
   * @param _router
   * @param _envService
   */
  constructor(
    private _authService: AuthService,
    private _tokenStorageService: TokenStorageService,
    private _router: Router,
    private _envService: EnvService
  ) {}

  /**
   * @param route - state The state of the r. This is used to determine whether or not we should try to authenticate and if we should return a response to the user '
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    if (!this._authService.isConnected()) {
      const requestedRoute: string = state?.url;
      return this._authService
        .getAuthenticationInfo()
        .toPromise()
        .then((authResponse: AuthorizationResponse) => {
          if (authResponse) {
            this._authService.setConnected(true);
            this._tokenStorageService.saveAuthorities(authResponse.authorities);
            this._tokenStorageService.saveProfilType(authResponse.profileTypeCode);
            this._tokenStorageService.saveAssociatedId(authResponse.id);
            this._tokenStorageService.saveAmeId(authResponse.ameId);
            this._tokenStorageService.saveAemcId(authResponse.aemcId);
            this._tokenStorageService.saveChefCentre(authResponse.centerManager);
            this._tokenStorageService.saveMedecinChef(authResponse.doctorManager);
            this._tokenStorageService.saveRequestId(authResponse.requestId);
            this._tokenStorageService.saveProfileCode(authResponse.profileCode);
            this.redirectToRequestDetail(authResponse.profileCode, authResponse.requestId, requestedRoute);
          }
        })
        .catch(() => {
          this._router.navigate(['login']);
        });
    } else {
      return Promise.resolve();
    }
  }

  /**
   * @param profileCode
   * @param requestId
   * @param requestedRoute
   *
   * @return { Promise } Resolves to the next page in the navigation stack or navigates to the home
   */
  redirectToRequestDetail(profileCode: string, requestId: number, requestedRoute?: string) {
    if (profileCode === 'CDT_AME') {
      this._router.navigate([`app/ame/approval-request/cl2/${requestId}`]);
      return;
    }

    if (profileCode === 'CDT_AEMC') {
      this._router.navigate([`app/aemc/candidate/${requestId}/detail`]);
      return;
    }

    if (requestedRoute) {
      this._router.navigateByUrl(requestedRoute);
      return;
    }

    this._router.navigate(['app', 'home']);
  }
}
