import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessageInterface } from './toast.message.interface';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private messageService: MessageService, private translateService: TranslateService) {}

  showWarn(toastMessage: ToastMessageInterface) {
    this.messageService.add({
      closable: true,
      key: 'id-top-center',
      life: 8000,
      sticky: toastMessage.sticky ?? false,
      severity: 'warn',
      summary: toastMessage.title
        ? this.translateService.instant(toastMessage.title)
        : this.translateService.instant('global.toast.warn.defaultTitle'),
      detail: toastMessage.description
        ? this.translateService.instant(toastMessage.description)
        : this.translateService.instant('global.toast.warn.defaultDescription'),
    });
  }

  showInfo(toastMessage: ToastMessageInterface) {
    this.messageService.add({
      closable: true,
      key: 'id-top-center',
      life: 8000,
      sticky: toastMessage.sticky ?? false,
      severity: 'info',
      summary: toastMessage.title
        ? this.translateService.instant(toastMessage.title)
        : this.translateService.instant('global.toast.info.defaultTitle'),
      detail: toastMessage.description
        ? this.translateService.instant(toastMessage.description)
        : this.translateService.instant('global.toast.info.defaultDescription'),
    });
  }

  showSuccess(toastMessage: ToastMessageInterface) {
    this.messageService.add({
      closable: true,
      key: 'id-top-center',
      life: 5000,
      sticky: toastMessage.sticky ?? false,
      severity: 'success',
      summary: toastMessage.title
        ? this.translateService.instant(toastMessage.title)
        : this.translateService.instant('global.toast.success.defaultTitle'),
      detail: toastMessage.description
        ? this.translateService.instant(toastMessage.description)
        : this.translateService.instant('global.toast.success.defaultDescription'),
    });
  }

  showError(toastMessage: ToastMessageInterface) {
    this.messageService.add({
      closable: true,
      key: 'id-top-center',
      sticky: toastMessage.sticky ?? false,
      life: 10000,
      severity: 'error',
      summary: toastMessage.title
        ? this.translateService.instant(toastMessage.title)
        : this.translateService.instant('global.toast.error.defaultTitle'),
      detail: toastMessage.description
        ? this.translateService.instant(toastMessage.description, { ...toastMessage.params })
        : this.translateService.instant('global.toast.error.defaultDescription'),
    });
  }

  showErrorFromHttp(response: HttpErrorResponse, defaultErrorDescription?: string) {
    if (response.error?.code) {
      this.showError({ description: 'global.errors.' + response.error.code, params: response.error.params });
    } else if (response.error?.message) {
      this.showError({
        description: response.error.message,
      });
    } else if (defaultErrorDescription) {
      this.showError({
        description: defaultErrorDescription,
      });
    } else {
      this.showError({ description: response.error });
    }
  }
}
