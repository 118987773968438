import { HttpRequest } from '@angular/common/http';

export class ServiceUtils {
  /**
   * Add authorization header with given token
   * @param request
   * @param token
   * @returns modified request
   */
  static addAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    //In case of error for WS verify we need to update token in body or we will have another failure
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }

  /**
   * Add a custom header to an http request
   * @param request http request
   * @param headerName Name of the created header
   * @param headerValue Value of the created header
   * @returns updated http request with the added header
   */
  static addCustomHeader(request: HttpRequest<any>, headerName: string, headerValue: string): HttpRequest<any> {
    //In case of error for WS verify we need to update token in body or we will have another failure
    return request.clone({
      headers: request.headers.set(headerName, headerValue),
    });
  }
}
